.App {
  text-align: center;
}
.app_header_height{
  height:60px;
  clear: both;
}
.container_roots
{padding: 0px;
  min-height:calc(100vh - 130px);
  max-width: 100%;
}
 

.container_size
{
  max-width: 1400px;
  margin:auto;
  padding: 10px;
  display: flow-root;
  clear: both;
 
}

.help-button
{
    float: right;
    cursor: pointer;
    line-height: 30px;
}
.help-button:hover{
  text-decoration: underline;
}
table{
  width: 100%;  
  border-collapse: collapse;
 
 
     font-size: var(--font_size_normal);
     border-radius: 5px; 
     border-collapse: collapse;
 
 }
 /* Global Styles */
h1, h2 {
  color: #333;
}

p, ul, ol, li {
  text-align: left;
}

/* Table Styles */
table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  line-height: 25px; 
}
table,th,tr{
  border-bottom: solid 3px var(--body_background);
 
 background-color: var(--layout_background);
}
table, th, td {
 
  padding: 2px 5px; 
}

th {
  background-color: var(--layout_background);
}

 


.body_grid_size {
  padding: 20px;
  line-height: 25px;
}
 
 
.master-menu {
  position: relative;
  min-height: 40px;
  padding: 0px 10px;
  font-size: var(--font_size_button);
  background-color: var(--layout_background);  
}

.master-menu .button-body {
  width: 100%;
  min-height: 40px;
  padding: 5px 0px;
  overflow-x: hidden;
  display: flow-root;
  box-sizing: border-box;
}

.master-menu .orders-div {
  position: absolute;
  min-height: 40px;
  right: 0px; /* same as .button-body padding */
  top: 0px;    /* aligns with padding */
  padding: 5px 0 0 0;
  white-space: nowrap; /* prevents wrapping */
}
 
/* Button Styling */
.master-menu .master-menu-button {
    display: inline-flex;
    align-items: center;  
    justify-content: center;                  
     float: left;
    color: var(--text_color_info);
    text-decoration: none;
    padding: 0px 5px 0px 15px; 
    margin: 0px 10px;
    font-size: var(--font_size_button);
    border-radius: 0px;
    height: 40px; 
    transition: transform 0.3s ease;
    cursor: pointer;
    line-height: 24px;
    border-bottom:solid 2px transparent;
  }
  
  /* Center-Aligned Text */
  .master-menu .menubutton-text {
    white-space: nowrap;        /* Prevents text wrapping */
    text-align: center;         /* Center-align text */
    color: var(--text_color_info); 
    margin: 0px 5px 0px 0px;
  }
  
  /* Center-Aligned Image */
  .master-menu .menubutton-icon {
    height: 22px;
    width: 22px;
    margin: 0px 5px 0px 0px;
    color: var(--text_color_info);
    transition: transform 0.3s ease;
  }
  .master-menu .master-menu-button.active
  {
    border-bottom:solid 2px var(--body_color);
  }
  .master-menu .master-menu-button.active .button-text
  {
    color: var(--body_color);
  }
  .master-menu .master-menu-button.active .menubutton-icon
  {
    color: var(--body_color);
  }
  /* Hover Effect */
  .master-menu  .master-menu-button:hover {
 
    -webkit-animation: pulse 1s ease-out;
  }
 
@media (max-width: 1280px) {
  .container_size {
    max-width: 100%;
    margin: 0 auto; /* centers it and prevents horizontal overflow */
    padding: 5px 100px;
    display: flow-root;
    clear: both;
  }
}
 
@media (max-width: 600px) {
  .container_size
  {
    max-width: 1300px;
    margin: auto; 
    padding: 5px;
    display: flow-root;
    clear: both;
  }
}

 
 
.icon {

  color: var(--icon_color);
  height: 24px;
  width: 24px;
}

.tabs {
  display: flex;
  justify-content: space-around;
  margin-bottom: 0px;
}

.tab {
  display:flow-root;
  align-items: center;
  gap: 2px;
  padding: 10px 2px;
  background-color: transparent;
  color: var(--text_color_info);
  border: 3px solid transparent;
  cursor: pointer;
  transition: 0.3s;
  outline: none;
  line-height: 14px;
  
  font-size: var(--font_size_button);
}

.tab:hover {
  background-color: transparent;
}

.tab.active {
  background-color: transparent;
  color: var(--text_color);
  border: 3px solid transparent;
  border-bottom: solid 2px var(--text_color);
}

.tab-icon {
  
  margin: 6px 5px 6px 1px;
  height: 30px;
  width: 30px;
}
.tab.active.tab-icon 
{
  color: var(--text_color);
}
.tab-content {
  padding: 20px;
  
  min-height: 150px;
  text-align: left;
}

@keyframes pulse {

  0% {

   box-shadow: 0 0 0 0 var(--theme_app_footer_color);

  }

 

  70% {

   box-shadow: 0 0 0 10px rgb(218 103 68 / 0%);

  }

 

  100% {

   box-shadow: 0 0 0 0 rgb(218 103 68 / 0%);

  }

 }
 