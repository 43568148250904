.header-div
{
    display: flow-root;
  
    margin-bottom: 30px;
    padding:5px 10px;
    width: 100%;
}
.header-div .hd-div
{
    float: left;
}
 
.price-header {
    font-size: var(--font_size_title);
 font-weight: 600;
    text-align:left;
    color: var(--primary_color);
  }
  .price-sub-header {
    font-size: var(--font_size_normal);
    
    text-align:left;
    color: var(--primary_color);
  }
  
  .card-price-body {
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .card-price-body .card-price {
    min-height: 250px;
    width: 350px;
    border: solid 1px var(--border_color);
    border-radius: 10px;
    padding: 10px;
    cursor: pointer;
    text-align: left;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
    background-color: #fff;
  }
  .card-price-body .card-price:hover
  {   box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    -webkit-animation: pulse 1s ease-in-out;
  }
  .card-price .product-img
  {
    max-width: 100%;
    object-fit: contain;
    min-height: 100px;
  }
  .card-price .name-price{
    font-size:var(--font_size_title);
    font-weight: 600;
  }
  .card-price .title-price{
    font-size:var(--font_size_normal);
    font-weight: 400;
  }
  
  .card-price-amount {
    font-size: 20px;
    color: var(--highlight_color);
    font-weight: bold;
  }
  
  .card-validity {
    color: #666;
    font-size: 14px;
    margin-top: 10px;
  }
  
  .buy-button {
    margin-top: 5px;
    background-color: var(--theme_color);
    font-size:var(--font_size_button);
    border: none;
    padding: 10px 30px;
    cursor: pointer;
    border-radius: 6px;
  }
  .price-info-section {
    margin-top: 40px;
    padding: 20px;
    border-top: 1px solid var(--border_color);
    
  }
  
  .price-info-section h2 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 15px;
  }
  
  .info-list {
    list-style: none;
    padding-left: 0;
  }
  
  .info-list li {
    margin-bottom: 10px;
  }
  
  .info-list a {
    color: #0078d4;
    text-decoration: none;
  }
  
  .info-list a:hover {
    text-decoration: underline;
  }
  
  