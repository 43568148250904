
  
  /* Design page */
  .design_container_roots
  {
    border: solid 1px var(--border_color);
    margin: 10px;
    min-height: calc(100vh -  300px);
    border-radius: 10px;
   padding-bottom: 20px;

  }

  .design_container_roots .design_container_size
  {
    min-height: calc(100vh - 300px);  
  }
  .container_design
  { 
   
    border-radius: 8px;  
    padding: 20px;
  }

  .design_container_roots .design_container_size .input-body
  {
    max-width: 1400px;
    margin: auto;
    display: flow-root;
    padding: 20px 0px;
  }
  

  .service-menu-div
  {
     
    min-height: 40px;
    max-width: 100%;
    border-top-left-radius:9px; 
    border-top-right-radius:9px; 
    display: flow-root;
    padding: 5px 5px;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    touch-callout: none; /* Prevents callout menu on long press */
    -webkit-touch-callout: none;
    border-bottom: solid 1px var(--border_color); 
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    touch-callout: none; /* Prevents callout menu on long press */
    -webkit-touch-callout: none;
  }
  .service-menu-div .left-div
  {
    float: left;
    display: flex;
    padding: 0px 10px;
  }
  .service-menu-div .stock-cart
  {    font-size: var(--font_size_title);
    float: left;
    height:24px;
    margin: 8px 5px;
    width: 24px;
    cursor: pointer;
  }
    .stock-text
  {    font-size: var(--font_size_normal);
    float: left;
    line-height: 40px;
    margin: 0px 5px; 
    font-weight: 400;
  }
  .service-menu-div .right-div
  {
    float: right;
    display: flow-root;
  }
  
 
  .service-menu-div .prumicm-switch-wrapper
  {    font-size: var(--font_size_title);
    float: left;
    line-height: 40px;
    font-weight: 400;
    margin-left: 20px;
    padding: 4px 0px;
 
    cursor: pointer;
  
    border-bottom-right-radius:5px; 
  }
  .service-menu-div .new-create-btn
  {    font-size: var(--font_size_title);
    float: left;
    line-height: 40px;
    min-height: 40px;
    font-weight: 600;
   display: none;
    padding: 0px 20px;
  width: 80px;
    cursor: pointer;
    border: solid 1px var(--theme_color);
    background-color: var(--theme_color);
    border-radius:5px; 
  }
  .service-menu-div .new-create-btn:hover
  {-webkit-animation: pulse 1s ease-out;
     
 
   
  }
  .service-menu-div .create-btn
  {    font-size: var(--font_size_title);
    float: left;
    line-height: 40px;
    min-height: 40px;
    font-weight: 600;
  
    padding: 0px 20px;
  width: 80px;
    cursor: pointer;
    border: solid 1px var(--border_color);
    background-color: var(--layout_background);
    border-radius:5px; 
  }
  .service-menu-div .create-btn:hover
  {-webkit-animation: pulse 1s ease-out;
     
 
   
  }
  
  .service-menu-div .down-btn
  {  border-radius:5px; 
    padding:5px 2px;
    border: solid 1px var(--border_color);
    min-height: 30px;
    width: 50px;
float: right;
background-color: var(--layout_background);
 display: none;
border-radius: 5px;
cursor: pointer;
display: none;
  }
  .service-menu-div .down-btn:hover
  
  {
    -webkit-animation: pulse 1s ease-out;
 
  }
  .output-body
{
    max-width: 100%;
    clear: both;
   display: none;
}
.output-body .output_div
{ display: none;
outline: none;
font-size: var(--font_size_title);
min-height: 20px;
resize:vertical ;
padding: 2px 10px;
border-radius: 5px;
clear: both;
font-weight: 400;
font-family: Arial, sans-serif;
border: solid 1px var(--border_color);
}
.output-body .output_div .output-url-text
{
  font-weight: 400;
  font-size: var(--font_size_title);
    text-align: left;
}
.output-body .output-btn-div
{ 
 
font-size: var(--font_size_title);
min-height: 60px;
resize:vertical ;
padding: 10px 0px;
border-radius: 5px;
display: flow-root;
clear: both;

font-weight: 400;
font-family: Arial, sans-serif; 
}
.output-body .output-btn-div .bun-div
{ 
outline: none;
float: left;
font-size: var(--font_size_title);
 
resize:vertical ;
padding: 5px 10px;
border-radius: 5px;
margin: 0px 5px 0px 5px;
cursor: pointer; 
font-weight: 400;
font-family: Arial, sans-serif; 
border: solid 1px var(--border_color);
}
.output-body .output-btn-div .bun-div .img-icon
{
width: 22px;
height: 22px;
float: left;
margin: 3px 0px;
}
.output-body .output-btn-div .bun-div .span-text
{
line-height: 22px;

float: left;
margin: 3px 5px;
}

  @media (max-width: 1280px) {
   
    .design_container_roots .design_container_size
    {
      min-height: calc(100vh - 300px);  
    }
    .container_design
    {  
      border-radius: 8px;  
      padding:0 50px;
    }
    .design_container_roots .design_container_size .input-body
    {
      max-width: 100%;
      margin:0 5px auto;
      display: flow-root;
      padding: 10px 1px;
    }
    
  .service-menu-div
  {
     
    min-height: 35px;
    max-width: 100%;
    border-top-left-radius:9px; 
    border-top-right-radius:9px; 
    display: flow-root;
    padding: 2px 5px;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    touch-callout: none; /* Prevents callout menu on long press */
    -webkit-touch-callout: none;
    border-bottom: solid 1px var(--border_color); 
    
  }
  .service-menu-div .left-div
  {
    float: left;
    display: flex;
    padding: 0px 10px;
  }
  .service-menu-div .stock-cart
  {    font-size: var(--font_size_title);
    float: left;
    height:24px;
    margin: 5px 5px;
    width: 24px;
    cursor: pointer;
  }
    .stock-text
  {    font-size: var(--font_size_normal);
    float: left;
    line-height: 35px;
    margin: 0px 5px; 
    font-weight: 400;
  }
    .service-menu-div .create-btn
    {    font-size: var(--font_size_title);
      float: left;
      line-height: 35px;
      min-height: 35px;
      font-weight: 600;
    
      padding: 0px 20px;
    width: 80px;
      cursor: pointer;
      border: solid 1px var(--border_color);
      background-color: var(--layout_background);
      border-radius:5px; 
    }
    .service-menu-div .create-btn:hover
    {-webkit-animation: pulse 1s ease-out;
        
    }
    .service-menu-div .down-btn
    {  border-radius:5px; 
      padding:2px 2px;
      border: solid 1px var(--border_color);
      min-height: 30px;
      width: 50px;
  float: right;
  background-color: var(--layout_background);
   display: none;
  border-radius: 5px;
  cursor: pointer;
  display: none;
    }
    .service-menu-div .down-btn:hover
    
    {
      -webkit-animation: pulse 1s ease-out;
   
    }
  }
 
  @media (max-width: 600px) {
      /* Design page */
  .design_container_roots
  {
    border:none 1px var(--border_color);
    margin: 5px auto;
    border-radius: 10px;
   padding-bottom: 10px;
  }
  .design_container_roots .design_container_size
  {
    min-height: 100px;  
    margin: 0px 5px;
  }
  .container_design
  { 
    min-height: 10px;     
    border-radius: 8px;  
    padding:0px 5px;
  }
  .design_container_roots .design_container_size .input-body
  {
    max-width: 1400px;
    margin: auto;
    display: flow-root;
    padding: 5px 0px;
  }
  
  .service-menu-div
  {
     
    min-height: 40px;
    max-width: 100%;
    border-top-left-radius:9px; 
    border-top-right-radius:9px; 
    display: flow-root;
    padding: 0px 10px;
    user-select: none;          
    -webkit-user-select: none;
    -moz-user-select: none;      
    -ms-user-select: none;    
    border-bottom: solid 1px var(--border_color); 
    
  }
  .service-menu-div .left-div
  {
    float:left;
    display:flow-root;
   
    padding: 0px 0px;
 
  }
  .service-menu-div .stock-cart
  {    font-size: var(--font_size_title);
    float: left;
    height:24px;
    margin: 6px 5px;
    width: 24px;
    cursor: pointer;
  }
    .stock-text
  {    font-size: var(--font_size_normal);
    float: left;
    line-height: 40px;
    margin: 0px 5px;
    cursor: pointer;
    font-weight: 400;
  }
  .service-menu-div .right-div
  {
    float: right;
    padding: 0px 0px 0px 0px;
    display: flow-root; 
    
    margin-bottom: 5px;
     
  }
  
  .service-menu-div .scan-alert
  {  font-size: var(--font_size_normal);
    float: left;
    line-height: 40px;
    margin: 0px 0px;
    cursor: pointer;
    font-weight: 400;
  }
  .service-menu-div .prumicm-switch-wrapper
  {    font-size: var(--font_size_title);
    float: right;
    line-height: 40px;
    font-weight: 400;
    margin-left: 20px;
    padding: 2px 0px;
 
    cursor: pointer;
  
    border-bottom-right-radius:5px; 
  }
  
  .service-menu-div .create-btn
  {    font-size: var(--font_size_title);
    float: right;
    line-height: 40px;
    min-height: 40px;
    font-weight: 600;
  
    padding: 0px 20px;
  width: 60px;
    cursor: pointer;
    border: solid 1px var(--border_color);
    background-color: var(--layout_background);
    border-radius:5px; 
  }
  .service-menu-div .create-btn:hover
  {-webkit-animation: pulse 1s ease-out;
  
  }
  
  .service-menu-div .down-btn
  {  border-radius:5px; 
    padding:5px 10px;
    border: solid 1px var(--border_color);
    min-height: 30px;
    width: 30px;
    float: right;
background-color: var(--layout_background);
display: none;
border-radius: 5px;
cursor: pointer;
 
  }
  .service-menu-div .down-btn:hover
  
  { 
-webkit-animation: pulse 1s ease-out;
  }
  }
  