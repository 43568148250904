 
  h1 {
    margin: 0;
    font-size: 26px;
  }
  .section {
    max-width: 1200px;
    padding: 20px;
    margin: 10px auto;
    border-radius: 8px;
    text-align: left;
    background: var(--layout_background);
    box-sizing: border-box;
  }
  .section h2 {
    margin-top: 0;
    font-size: 22px;
  }
  .cta {
    text-align: center;
    margin: 20px 0;
  }
  .cta a {
    display: inline-block;
    background: var(--layout_background);
 color: var(--text_color);
    padding: 12px 24px;
    text-decoration: none;
    border-radius: 5px;
    font-size: 18px;
    transition: 0.3s;
  }
  .cta a:hover {
    -webkit-animation: pulse 1s ease-out;
  }
  