.tag_div {
  
    text-align: left;
      display: flow-root;
    z-index: 2;
     align-items: center;
     clear: both;
     max-width: 600px;
   padding: 2px;
    }
.tag_div .tag_line {

    font-size: var(--font_size_header);
 
    line-height:20px;
  
    font-weight: 600;
  
  }
.tag_div .tag_line2 {
  
    font-size: var(--font_size_normal);
  
    font-weight: 400;

    line-height: 15px;
  
    margin: 10px 0px;
  
  }
.tab-content-text { 
    font-size: var(--font_size_normal);
    text-align: left;
    padding: 0px; 
    float: left;
    width: calc(100% - 400px);
  }
 .title_text 
    {
font-size:var(--font_size_title);
 font-weight: 400;
 margin: 1px 0px 1px 0px;
 line-height: 24px;
 text-align: left;
float: left;
    }
    .title_text .img-icon
    {
height: 24px;
width: 24px;
margin: 1px 10px 1px 0px;
      float: left;
      color: var(--icon_color);
    }
    .title_text .span-text
    {
      float: left;
  font-weight: 500;
    }
     .example_text
    {
font-size:var(--font_size_info);
 font-weight: 400;
 color: var(--text_color_info);
 margin: 5px 0px 10px 0px;
float: left;
    }
  .tab-input-button
  {
    font-size: var(--font_size_normal);
     font-weight: 600;
     margin: 0px 0px 0px 0px;
     padding: 5px 0px 0px 0px;
     display: flow-root;
     display: none;
     align-items: center;
     border-bottom: solid 2px var(--border_color);
     background-color: var(--layout_background);
  }
  /* Button Styling */
  .tab-content-text .tab-input-button .master-menu-button {
    display: flex;
    flex-direction: column;        /* Stack icon on top, text below */
    align-items: center;           /* Center horizontally */
    justify-content: center;       /* Center vertically if needed */
    float: left;  
    color: var(--text_color_info);
    text-decoration: none;  
    margin: 0px 4% -2px 4%;  
    padding: 5px 20px;
    border-radius: 0px;
    min-height: 50px;              /* Enough height for icon + text */
    transition: transform 0.3s ease;
    cursor: pointer;
    border-bottom: solid 2px transparent;
  }  
  /* Icon styles */
  .tab-content-text .tab-input-button .menubutton-icon {
    height: 26px;
    width: 26px;
    color: var(--text_color_info);
    margin-bottom: 4px;            /* Space between icon and text */
    transition: transform 0.3s ease;
  }  
  /* Text styles */
  .tab-content-text .tab-input-button .button-text {
    text-align: center;
    color: var(--text_color_info);
    font-size: var(--font_size_normal);
    line-height: 14px;
    font-weight: 600;
  }  
  /* Active state */
  .tab-content-text .tab-input-button .master-menu-button.active {
    border-bottom: solid 2px var(--body_color);
  }
  .tab-content-text .tab-input-button .master-menu-button.active .menubutton-icon,
  .tab-content-text .tab-input-button .master-menu-button.active .button-text {
    color: var(--body_color);
  } 
  .tab-content-text .input-div
  {
    min-height: 10px;
 
    margin: 5px 0px;

    
    padding: 2px;
  }
  .input-div .input_textbox
  { 
outline: none;
font-size: var(--font_size_title);
max-height: 200px;
resize:vertical ;
 
font-weight: 400;
font-family: Arial, sans-serif;
border: solid 1px var(--border_color);
  }

  .qrcode-content-text { 
    font-size: var(--font_size_normal);
    text-align: left;
    padding: 0px;
    float: right;
 display: none;
    margin: 5px;
    padding: 5px;
    background-color: var(--layout_background);
    border-radius: 5px;
    border: solid 1px var(--border_color);
  }
  .qrcode-content-text .qrcode-content { 
    font-size: var(--font_size_normal);  
    padding: 5px;     
    min-height: 100px; 
    border-radius: 5px;
    background-color: var(--border_color);
    border-radius: 5px;
    position: relative;
    display: inline-block;
    background-color: #fff;
  }
  .qrcode-content-text .qrcode-content .logo_img
  { border-radius: 5px; 
    background-color: #fff;
    padding: 5px;
    position:absolute; 
     margin: auto;   
    width: 40px;
    height: 40px;
  }
    .qrcode-content-text .qrcodecanvas
  {
 
    background-color: #fff;
    padding: 10px;
  }
 
 
  .stock-info-text
  {    font-size: var(--font_size_normal);
    float: left;
    max-width:600px;
    text-align: left;
    margin: 5px 0px; 
line-height: 18px;
    font-weight: 400;
  }
  .stock-info-text .title-text
  {
font-size: var(--font_size_title);
font-weight: 600;
padding: 10px 10px;
 border-radius: 5px;
border:dotted 2px var(--border_color);
margin: 5px 0px;
  }
  .stock-info-text .header-text
  {
font-size: var(--font_size_header);
font-weight: 600;
margin: 5px 0px;
  }
  .stock-info-text .buy-btn
  {
    background-color: var(--layout_background);
    border-radius: 30px;
    max-width: 100px;
    text-align: center;
    font-size: var(--font_size_button);
    border:solid 1px var(--border_color);
    cursor: pointer;
    font-weight: 600;
    margin: 10px 0px;
    padding: 10px 20px;
  }  .stock-info-text .buy-btn:hover{
    -webkit-animation: pulse 1s ease-out;
  } 
  .stock-info-text .price-text
  {
font-size: var(--font_size_header);
font-weight: 600;  
margin: 10px 0px;
  }
  
  /* Left Section */
  .Slideshow-order-left {
  
     max-width:400px; 
    display: flex;
    
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
  }
  
  .Slideshow-order-left .relative-order-left {
    position: relative;
    width: 100%;
  }
  
  /* Product Image */
 
  .Slideshow-order-left .relative-order-left 
  {  
    position: relative;
    width: 100%;
  }
  
  
  .Slideshow-order-left .relative-order-left .photo-img {
    width:calc(100% - 1px); 
 
    object-fit:contain;
    border-radius: 5px;   
   z-index:0;
  
  }
  .relative-order-left .nav_div
  {
background-color:var(--layout_background);
display: flow-root;
min-height: 50px;
  }
  /* Navigation Buttons */
  .relative-order-left .nav_div .Previous-btn {
    font-size: var(--font_size_bg);
    cursor: pointer; 
    border: none;
    color: #000;
 float: left;
   min-height: 40px;
    padding: 5px 10px; 
    z-index: 100; 
    background-color:var(--layout_background);
  }
  
  .relative-order-left .nav_div .Next-btn {
    font-size: var(--font_size_bg);
    cursor: pointer; 
      float: right;
      border: none;
      color: #000;
      min-height: 40px;
    padding: 5px 10px; 
    z-index: 100; 
    background-color:var(--layout_background);
  }
  @media (max-width: 1280px) {
    
  /* Button Styling */
  .tab-content-text .tab-input-button .master-menu-button {
    display: flex;
    flex-direction: column;        /* Stack icon on top, text below */
    align-items: center;           /* Center horizontally */
    justify-content: center;       /* Center vertically if needed */
    float: left;  
    color: var(--text_color_info);
    text-decoration: none;  
    margin: 2px 3% -2px 3%;  
    padding: 2px 5px;
    border-radius: 0px;
    min-height: 30px;              /* Enough height for icon + text */
    transition: transform 0.3s ease;
    cursor: pointer;
    border-bottom: solid 2px transparent;
  }  
  /* Icon styles */
  .tab-content-text .tab-input-button .menubutton-icon {
    height: 24px;
    width: 24px;
    color: var(--text_color_info);
    margin-bottom: 5px;            /* Space between icon and text */
    transition: transform 0.3s ease;
  }  
  /* Text styles */
  .tab-content-text .tab-input-button .button-text {
    text-align: center;
    color: var(--text_color_info);
    font-size: var(--font_size_normal);
    line-height: 15px;
    font-weight: 400;
  }  
  }
  @media (max-width: 600px) {

    .tag_div {
 background-color: var(--layout_background);
        margin: auto;
           width: calc(100% - 20px);
         text-align: left;

           display: flow-root;
         z-index: 2;
          align-items: center;
          max-width: 600px;
          clear: both;
        padding: 5px 10px;
         }
       .tag_div .tag_line {
        
          font-size:var(--font_size_title);
        
        
        
          font-weight: 600;
        
        }
        .tag_div .tag_line2 {
        
          font-size:var(--font_size_normal);
        
          font-weight: 500; 
        
          margin: 5px 0px;
        
        }
   
          
         .container_design
         {
           
             
             min-height: 100px;     
             border-radius: 8px;  
             
         }
         .input-body
         {
             max-width: 100%;
             display: flow-root;
         }
         .tab-content-text { 
             font-size: var(--font_size_normal);
             text-align: left;
             padding: 10px 0px;
             float: none;
             
             clear: both;
             width: calc(100% - 10px);
             margin: auto;
           }
         .input-div
           {
             min-height: 10px;
          
             margin: 10px 0px;
             
              
             padding: 2px;
           }
            .input-div .input_textbox
           { 
         outline: none;
         font-size: var(--font_size_title);
         border: solid 2px var(--border_color);
           }
         
           .qrcode-content-text { 
             font-size: var(--font_size_normal);
             text-align: left;
             padding: 0px;
             float: none;
          display: none;
             margin: 5px auto;
             padding: 5px;
             width: 310px;
             background-color: var(--border_color);
             border-radius: 5px;
           
           }
           .qrcode-content-text .qrcode-content { 
             font-size: var(--font_size_normal);  
             padding: 5px;     
             min-height: 100px; 
         
             border-radius: 5px;
             background-color: var(--border_color);
             border-radius: 5px;
             position: relative;
             display: inline-block;
             background-color: #fff;
             margin: auto;
           }
           .qrcode-content-text .qrcode-content .logo_img
           { border-radius: 5px; 
             background-color: #fff;
             padding: 5px;
             position:absolute; 
              margin: auto;   
             width: 40px;
             height: 40px;
           }
             .qrcode-content-text .qrcodecanvas
           {
          
             background-color: #fff;
             padding: 10px;
           }
        
        
           .stock-info-text
           {    font-size: var(--font_size_normal);
             float: left;
             max-width:600px;
             text-align: left;
             margin:10px;
     
             cursor: pointer;
         line-height: 18px;
             font-weight: 400;
           }
           .stock-info-text .title-text
           {
         font-size: var(--font_size_normal);
         font-weight: 600;
         padding: 10px 10px;
          border-radius: 5px;
         border:dotted 2px var(--border_color);
         margin: 5px 0px;
           }
           .stock-info-text .header-text
           {
         font-size: var(--font_size_title);
         font-weight: 600;
         margin: 5px 0px;
           }
           .stock-info-text
           {    font-size: var(--font_size_normal);
             float: left;
             text-align: left;
             margin: 5px 5px;
             cursor: pointer;
             font-weight: 400;
           } 
           
  .tab-content-text .tab-input-button
  {
    font-size: var(--font_size_normal);
     font-weight: 600;
     margin: 0px 0px 0px 0px;
     display: flow-root;
     display: none;
  }
  /* Button Styling */
  .tab-content-text .tab-input-button .master-menu-button {
    display: flex;
    flex-direction: column;        /* Stack icon on top, text below */
    align-items: center;           /* Center horizontally */
    justify-content: center;       /* Center vertically if needed */
    float: left;  
    color: var(--text_color_info);
    text-decoration: none;  
    margin: 2px 2% -2px 2%;  
    padding: 2px 1px;
    border-radius: 0px;
    min-height: 30px;              /* Enough height for icon + text */
    transition: transform 0.3s ease;
    cursor: pointer;
    border-bottom: solid 2px transparent;
  }  
  /* Icon styles */
  .tab-content-text .tab-input-button .menubutton-icon {
    height: 24px;
    width: 24px;
    color: var(--text_color_info);
    margin-bottom: 5px;            /* Space between icon and text */
    transition: transform 0.3s ease;
  }  
  /* Text styles */
  .tab-content-text .tab-input-button .button-text {
    text-align: center;
    color: var(--text_color_info);
    font-size: var(--font_size_normal);
    line-height: 12px;
    font-weight: 400;
  }  
  }